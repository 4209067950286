<template>
  <div class="main">
    <div style="min-height: 500px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>面试管理</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="formBox">
        <div class="input">
          <span class="label">公司名称:</span>
          <el-input
            v-model="formInline.orgName"
            style="width: 190px"
            placeholder="请输入企业名称"
          ></el-input>
        </div>

        <div class="timeInput">
          <span class="label">投递时间:</span>
          <el-date-picker
            v-model="formInline.time"
            type="daterange"
            align="center"
            range-separator="~"
            style="width: 190px"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>

        <div class="search-btn">
          <el-button type="primary" icon="el-icon-search" @click="onSubmit"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-left" @click="resetForm"
            >重置</el-button
          >
        </div>
      </div>

      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, inx) in tabList"
          :key="inx"
          :label="item.label"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>

      <el-table
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :current-page.sync="params.pageNo"
        style="width: 100%"
        :key="isKey"
        @cell-click="toDatum"
      >
        <!-- <el-table-column align="center" type="selection" min-width="5%">
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="orgName"
          label="公司名称"
          min-width="35%"
        >
        </el-table-column>
        <el-table-column
          prop="postName"
          align="center"
          label="职位名称"
          min-width="20%"
        >
        </el-table-column>

        <el-table-column
          align="center"
          prop="pay"
          label="薪资"
          min-width="20%"
        >
        <template slot-scope="scope">
            <span>{{
              scope.row.pay  ?  getPay(scope.row.pay)  : ''
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="interview_status"
          label="面试状态"
          min-width="20%"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.interview_status == 0">未邀约</span>
            <span v-if="scope.row.interview_status == 1">已邀约</span>
            <span v-if="scope.row.interview_status == 2">待面试</span>
            <span v-if="scope.row.interview_status == 3">已录用</span>
            <span v-if="scope.row.interview_status == 4">已面试</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="interview_time"
          label="面试时间"
          min-width="25%"
        >
          <template slot-scope="scope">
            <!-- <span>{{
              scope.row.interview_time
                ? scope.row.interview_time.substring(0, 16)
                : ""
            }}</span> -->
            <span v-if="scope.row.interview_time">
              {{ moment(scope.row.interview_time).format('YYYY-MM-DD') }} <br>{{ moment(scope.row.interview_time).format('HH:mm') }}
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="create_time"
          label="投递时间"
          min-width="28%"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.create_time">
              <!-- {{
              scope.row.create_time
                ? scope.row.create_time.substring(0, 16)
                : ""
            }} -->
            {{ moment(scope.row.create_time).format('YYYY-MM-DD') }} <br>{{ moment(scope.row.create_time).format('HH:mm') }}</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="userName"
          label="联系人"
         width="110px"
        >
          <template  slot-scope="scope">
            <!-- {{scope.row.userName}} -->

            <img src="@/img/message.png"  style="width: 20px;height: 20px;line-height: 28px;"  alt=""  @click.stop="sendMessage(scope.row)"  />
              <!-- <i class="el-icon-chat-dot-square" @click.stop="toNotice(scope.row)"></i> -->
          </template>
        </el-table-column>

        <el-table-column align="center" label="面试操作" min-width="15%">
          <template slot-scope="scope">
            <div class="viewDetails" v-if="scope.row.interview_status == 1">
              <!-- <img
              src="@/assets/personCenter/icon_zaixianmianshi_nor.png"
              alt="在线面试"
            /> -->
              <el-tooltip slot="reference" content="同意面试" placement="top">
                <img
                  @click.stop="agree(scope.row)"
                  src="@/assets/personCenter/icon_tongyi_nor.png"
                  alt=""
                />
              </el-tooltip>
              <el-tooltip content="拒绝面试" placement="top">
                <img
                  @click.stop="refuse(scope.row)"
                  src="@/assets/personCenter/icon_jujue_nor.png"
                  alt="拒绝面试"
                />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 20px">
        <el-pagination
          :background="true"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <el-dialog
      :visible.sync="visibleIM"
      width="1240px"
      top="10vh"
      :before-close="handleClose"
    >
      <Notice v-if="visibleIM"></Notice>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { getDict } from "@/api/system/system";
import { jobApply, jobApplyInv } from "@/api/personalCenter/personalCenter";
import { mapState } from 'vuex'
import Notice from '@/components/notice/notice.vue'
import { detailTeam } from "@/api/team/team";
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import moment from "moment";
export default {
  components: {
    Notice
  },

  data() {
    return {
      total: 1,
      isKey: false,
      userId: '',
      visibleIM: false,
      orgDetails: {}, //企业详情

      formInline: {
        orgName: "",
        time: "",
      },
      params: {
        pageNo: 1,
        pageSize: 10,
        jobUserId: "",
        orgName: "",
        btime: "",
        ntime: "",
      },
      total: 0,
      activeName: "all",
      tabList: [
        {
          label: "全部",
          name: "all",
        },
        {
          label: "未邀约",
          name: "0",
        },
        {
          label: "已邀约",
          name: "1",
        },
        {
          label: "待面试",
          name: "2",
        },
        // {
        //   label: "已面试",
        //   name: "4",
        // },
        {
          label: "已录用",
          name: "3",
        },
      ],
      tableData: [],
      multipleSelection: "",
      jobPay: [],
    };
  },

   computed: {
    ...mapState(['peopleList']),
  },

  created() {
    this._getDict();
    this.userId = localStorage.getItem('userId')
    this.getList();
  },
  methods: {
    moment,
    _getDict() {
      getDict({}).then((res) => {
        if (res.code == 200 && res.message === "请求成功") {
          localStorage.setItem("dict", JSON.stringify(res.data));
          this.dict = JSON.parse(localStorage.getItem("dict"));
          this.jobPay = this.dict.filter(
            (i) => i.code == "job_pay"
          )[0].children;
        }
      });
    },
    async getList() {
      this.params.jobUserId = localStorage.getItem("userId");
      let res = await jobApply(this.params);
      this.tableData = res.data.rows ? res.data.rows : [];
      if (res) {
        this.isKey = !this.isKey;
      }
      this.total = res.data.totalRows;
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getList();
    },
    //跳转公司名称和岗位名称
    toDatum(e) {
      if(e.job_post_id && e.postName){
        this.$router.push({ path: "/datum", query: { id: e.job_post_id } });
      }

    },

     handleClose() {
      this.visibleIM = false
    },

    getPay(code) {
      if(this.jobPay.length > 0 && code){
        return this.jobPay.filter((i) => i.code == code)[0].name;
      }else{
        return ''
      }
    },

     // 发送消息
    async sendMessage(scope) {
       if (!localStorage.getItem('userId')) {
        this.$router.push('/login')
        return
      }
      let res = await getJobUserAuth()
      let userAuth = res.data
      let jobUserId = localStorage.getItem("userId");
      let info = await detailTeam({ jobUserId })
      let userTeam = info.data
       if(userAuth != null || userTeam != null && userTeam.auditStatus == 2){
        this.visibleIM = true
      if (
        this.peopleList.find(i => i.userProfile.userID == scope.job_org_id)
      ) {
        return
      }
      let peopleList = this.peopleList
       let obj = {
        userProfile: {
          userID:scope.job_org_id,
          nick:scope.orgName,
        },
      }
      this.peopleList.unshift(obj)
      this.$store.commit('set_peopleList', peopleList)
       }else{
        this.$message.warning('请认证(个人/班组)后进行沟通')
      }
      console.log(this.peopleList, 'peopleListpeopleListpeopleList')
    },

    handleCurrentChange(val) {
      this.params.pageNo = val;
      this.getList();
    },
    handleClick(tab) {
      this.params.interviewStatus = tab.name == "all" ? "" : tab.name;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    isRemind(e) {
      console.log(e);
      e.remind = !e.remind;
      console.log(this.tableData);
    },
    agree(item) {
      let curDate = new Date()
      let beginDate = new Date(item.interview_time)
      if(curDate <= beginDate){
         let params = [
        {
          id: item.id,
          interviewStatus: 2,
        },
      ];
      jobApplyInv(params).then((res) => {
        this.$message({
          message: "已同意面试邀请",
          type: "success",
        });
        this.getList();
      });
      }else{
        this.$message({
          message: "面试时间已过期",
          type: "warning",
        });
      }
    },
    refuse(item) {
      let params = [
        {
          id: item.id,
          interviewStatus: 4,
        },
      ];
      jobApplyInv(params).then((res) => {
        this.$message({
          showClose: true,
          message: "已拒绝面试邀请",
        });
        this.getList();
      });
    },

    getPartTime(val) {
      var timearr = val.replace(" ", ":").replace(/:/g, "-").split("-");
      var timestr =
        "" + Number(timearr[0]) + "-" + timearr[1] + "-" + timearr[2];
      return timestr;
    },

    onSubmit() {
      if (this.formInline.orgName) {
        this.params.orgName = this.formInline.orgName;
      }
      if (this.formInline.time) {
        this.params.btime = this.formInline.time[0] + "+00:00:00";
        this.params.ntime = this.formInline.time[1] + "+23:59:59";
      }
      this.getList();
    },
    resetForm() {
      this.formInline.orgName = "";
      this.params.orgName = "";
      this.formInline.time = "";
      this.params.btime = "";
      this.params.ntime = "";
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.formBox {
  background-color: #fff;
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  .label {
    margin-right: 5px;
  }
}


    .el-icon-chat-dot-square {
      font-size: 20px;
      color: #029aff;
      margin-left:20px;
  }

// ::v-deep .el-form {
//   padding-top: 20px;
//   display: flex;
//   justify-content: space-around;
//   .el-form-item {
//     .el-form-item__content {
//       .el-input {
//         width: 100% !important;
//       }
//     }
//     .el-form-item__content:nth-child(1) {
//       width: 180px;
//     }
//     .el-form-item__content:nth-child(2) {
//       width: 146px;
//     }
//     .el-form-item__content:nth-child(3) {
//       width: 160px;
//     }
//   }
// }

::v-deep .el-tabs {
  // margin-top: 10px;
  background-color: #fff;
  .el-tabs__nav-wrap {
    background-color: #fafafa;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        border: none;
      }
      .is-active {
        background-color: #fff;
      }
    }
  }
}

.viewDetails {
  display: flex;
  justify-content: space-around;
  align-content: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
</style>
